import axios from "axios";
// const express = require('express')
// const cors = require('cors')
// const app = express()

// app.use(cors({origin: 'localhost://3000' }))

export const handleSubmitContactUsForm = async (reqData) => {
  const resData = await axios
    // .post("http://127.0.0.1:5001/apprely-b34c5/us-central1/submitContactUsForm", reqData)
    .post("https://submitcontactusform-fil5umw7uq-uc.a.run.app", reqData)
    .then((response) => {
      console.log("Response:", response);
      return true;
    })
    .catch((error) => {
      console.error("Error:", error);
      return false;
    });
  return resData;
};

export const handleApplicationForm = async (reqData) => {
  // const formData = new FormData();
  // formData.append("name", reqData.name);
  // formData.append("email", reqData.email);

  const resData = await axios
    // .post("http://127.0.0.1:5001/apprely-b34c5/us-central1/submitApplicationForm", reqData)
    .post("https://submitapplicationform-fil5umw7uq-uc.a.run.app", reqData)
    .then((response) => {
      console.log("Response:", response.data);
      return { status: true, data: "" };
    })
    .catch((error) => {
      console.error("Error:", error.response.data );
      return { status: false, data: error.response.data };
    });
  return resData;
};

export const handleSubmitMondayQueryForm = async (reqData) => {
  // const formData = new FormData();
  // formData.append("name", reqData.name);
  // formData.append("email", reqData.email);

  const resData = await axios
    // .post("http://127.0.0.1:5001/apprely-b34c5/us-central1/submitApplicationForm", reqData)
    .post("https://submitmondayqueryform-fil5umw7uq-uc.a.run.app", reqData)
    .then((response) => {
      console.log("Response:", response.data);
      return { status: true, data: "" };
    })
    .catch((error) => {
      console.error("Error:", error.response.data );
      return { status: false, data: error.response.data };
    });
  return resData;
};

export const handleSubmitChatbotUserForm = async (reqData) => {
  const resData = await axios
    // .post("http://127.0.0.1:5001/apprely-b34c5/us-central1/submitChatbotUserForm", reqData)
    .post("https://submitchatbotuserform-fil5umw7uq-uc.a.run.app", reqData)
    .then((response) => {
      console.log("Response:", response.data);
      return { status: true, data: "" };
    })
    .catch((error) => {
      console.error("Error:", error.response.data );
      return { status: false, data: error.response.data };
    });
  return resData;
};

